<template>
  <div class="course-detail">
    <!-- 课程封面 -->
    <div class="cover">
      <van-image  fit="cover" :src="good.introImg" />
      <!--<div class="intro">-->
        <!--<div class="name">{{ good.name }}</div>-->
        <!--&lt;!&ndash;<div class="desc">{{ course.desc }}</div>&ndash;&gt;-->
        <!--<div class="count">-->
          <!--<span>共 125 讲</span>-->
          <!--<span>999人订阅</span>-->
        <!--</div>-->
      <!--</div>-->
    </div>
    <div class="sell">
      <div class="price">
        <span style="color: #707070;">原价:￥{{ good.origPrice }}</span>
        <span style="margin-left: 30px;">优惠价:￥{{ good.marketPrice }}</span>
      </div>

    </div>
    <div class="describ">
      <span class="title">商品描述</span>
      <span class="content">{{course.describ}}</span>
    </div>
    <div class="standard">
      <van-radio-group v-model="checked" direction="horizontal">
        <van-radio style="margin-top: 10px" v-for="(item,index) in course.packs" :key="index" :name="index" @click="getChecked(item)">
          {{item.name}}
        </van-radio>
      </van-radio-group>
    </div>

    <!--<div class="goods-name">-->
      <!--<div class="name van-ellipsis">{{ good.name }}</div>-->
      <!--<div class="subtitle">{{ good.intro }}</div>-->
    <!--</div>-->

    <div class="delivery">
      <div class="label">发货</div>
      <div class="content">
        <div class="title">实付满49包邮（除新疆、西藏地区）</div>
        <div class="subtitle">付款后三个工作日内发货</div>
      </div>
    </div>

    <!-- 内容选项卡 -->
    <van-tabs v-model:active="active" sticky>
      <van-tab title="简介">
        <div class="feature">
          <div class="preface">
            <div class="content">
              <van-image width="100%" height="100%" fit="cover" :src="good.detailImg"  class="item" />
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="免费试听">
        <div class="catalog">
          <van-notice-bar left-icon="info-o" color="#1989fa" background="#ecf9ff">免费试听5讲，购买后解锁全部内容</van-notice-bar>
          <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="lessonFinishedText"
            @load="onLoadLessons"
          >
            <van-cell v-for="(item, index) in list" :key="item" center @click="playLesson(index)">
              <template #title>
                {{ item.title }}
              </template>
              <template #label>
                <div class="subtitle">{{ item.subtitle }}</div>
                <div class="count">
                  <span class="item"><van-icon name="play-circle-o" /> {{ item.plays }}</span>
                  <span class="item"><van-icon name="underway-o" /> {{ item.duration }}</span>
                </div>
              </template>
              <template #right-icon>
                <van-icon v-if="!item.trial" class-prefix="iconfont icon" name="lock" size="18" />
                <van-icon v-else-if="!item.playing" name="play-circle-o" size="18" />
                <van-icon v-else name="pause-circle-o" size="18" />
              </template>
            </van-cell>
          </van-list>
        </div>
      </van-tab>

    </van-tabs>

    <van-popup
      v-model:show="optionPopup"
      closeable
      close-icon-position="top-right"
      round
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="optionPopup">
        <div class="header">
          <van-image width="120" height="120" src="https://img.yzcdn.cn/vant/cat.jpeg" />
          <div class="info-right">
            <div class="price" v-if="!course.options || !Array.isArray(course.options) || course.options.length < 1 ">
              <span class="min-price">价格无效</span>
            </div>
            <div class="price" v-else-if="optionIndex > -1">
              <span class="min-price">￥{{ course.options[optionIndex].price }}</span>
            </div>
            <div class="price" v-else-if="course.price == course.maxPrice">
              <span class="min-price">￥{{ course.price }}</span>
            </div>
            <div class="price" v-else>
              <span class="min-price">￥{{ course.price }}</span><span class="to"> - </span><span class="max-price">{{ course.maxPrice }}</span>
            </div>

            <div class="kucun"><span class="label">库存 </span>{{841}}</div>
            <div class="yixuan"><span class="label">已选 </span>
              <span v-if="!course.options || !Array.isArray(course.options) || course.options.length < 1 || optionIndex < 0">无</span>
              <span v-else>{{course.options[optionIndex].title}}</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="label">全部选项</div>
          <div class="all">
            <span class="item" v-for="(item, index) in course.options" :key="index" :class="{ active: index == optionIndex }" @click="selectOption(index)">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 操作栏 -->
    <van-action-bar>
      <van-action-bar-icon icon="chat-o" text="客服" @click="showKefuPopup" />
      <van-action-bar-icon icon="play-circle" text="一键试听" @click="toTrial" v-if="!oneKeyStatus" />
      <van-action-bar-icon icon="pause-circle" text="一键试听" @click="oneKeyPause" v-else/>
      <!--<van-action-bar-icon icon="share-o" text="分享" @click="shareCourse" />-->
      <van-action-bar-icon icon="cart-o" text="购物车" :badge="carNum" @click="toCart"/>
      <van-action-bar-button type="warning" text="加入购物车" @click="addCar"/>
      <van-action-bar-button type="danger" text="立即购买" @click="buyNow" />
    </van-action-bar>

    <van-popup v-model:show="showKefu" class="kefuPopup">
      <div class="kefu-tips">长按下方二维码图片，选择“打开对方的企业微信名片”</div>
      <van-image width="206px" height="206px" :src="require('@/assets/images/1d23d0377d605c0aa4cb8fd6e519ffb.png')" />
    </van-popup>

    <van-share-sheet
      v-model:show="showShare"
      title="立即分享给好友"
      :options="options"
    />
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from 'vue';
import { showToast } from 'vant';
import { useRouter } from 'vue-router';
import { getCourseDetail, getLessonList, getEvalList,isDo  } from '@/service/course';
import {addShopCar,myCarNum} from '@/service/order'

export default {
  name: "CourseDetail",
  setup() {
    const checked = ref(0);
    const judge=ref(1);//是否购买或加入购物车的状态 1：可以操作 -1：库存不足 -2：全站会员不可以买单课
    const loginFlag = ref(false);//未登录
    const storage = localStorage.getItem("token-hyxt");
    const product = ref({});
    let products = [];
    const token = JSON.parse(storage);
    const carNum = ref(0);
    const router = useRouter();
    const good =ref({});
    const active = ref(0);
    const course = ref({});
    const oneKeyStatus = ref(false);
    const showKefu = ref(false);
    const showShare = ref(false);
    const audio = new Audio();
    const playIndex = ref(-1);
    const fiveStarts = ref(20);
    const optionIndex = ref(-1);  // 商品选项默认
    const optionPopup = ref(false); // 选项弹出层
    const state = reactive({
      list: [],
      page: 1,
      loading: false,
      finished: false,
      lessonFinishedText: '暂无数据',
      evalList: [],
      evalPage: 1,
      evalLoading: false,
      evalFinished: false,
      evalFinishedText: '暂无数据',
    });
    const options = [
      { name: '微信', icon: 'wechat' },
      { name: '朋友圈', icon: 'wechat-moments' },
      { name: '复制链接', icon: 'link', description: '描述信息' },
      { name: '分享海报', icon: 'poster' },
      { name: '二维码', icon: 'qrcode' },
    ];
    const onLoad = async (id) => {
      if(id) {
        let res = await getCourseDetail(id);
        course.value = res.data.data;
        good.value=course.value.packs[0];
        getJudgeStatus(id,good.value.id,token.userId);
      }
    };
    // 显示选项
    const showOptions = () => {
      if(!course.value.options || !Array.isArray(course.value.options) || course.value.options.length < 1) {
        showToast("该商品没有设置选项");
        return;
      }
      optionPopup.value = true;
    }
    const selectOption = (index) => {
      optionIndex.value = index;
      optionPopup.value = false;
    }

    const getChecked = (item) => {
      good.value=item;
      const id = router.currentRoute.value.query.id;
      getJudgeStatus(id,good.value.id,token.userId);
      //判断选择的商品是否能购买
      loadData();

    }
    const getJudgeStatus = async (courseShopId,packId,userId) =>{
      isDo (courseShopId,packId,userId).then(res=>{
        judge.value=res.data.status;
        console.log("获取判断值")
        console.log(judge.value)
      })
    }
    // 显示客服
    const showKefuPopup = () => {
      showKefu.value = true;
    };
    // 立即购买
    const buyNow = () => {
      if(loginFlag.value){
        //判断是否能购买
        if(-1==judge.value){
          showToast("该书籍暂无库存！");
        }else if(-2==judge.value){
          showToast("全站会员已拥有该课程！");
        }else{
          product.value.packId = good.value.id;
          product.value.amount = 1;
          products.push(product.value);
          router.push({path:"/pay/book", query:{goods: JSON.stringify(products)}});
        }
      }else{
        router.push("/login");
      }


    }

    // 读取数据
    const loadData = async () => {
      let res = await getLessonList(good.value.id);
      state.list = res.data.data;
      if(state.list.length < 1) {
        state.lessonFinishedText = "暂无数据";
        state.finished = true; //上拉结束
      }else if(state.list.length >= 10) {   //修改位res.data.list.length < 1 适配正式环境
        state.lessonFinishedText = "没有更多了";
        state.finished = true; //上拉结束
      } else {
        state.loading = false;
      }
    };
    // 加载数据
    const onLoadLessons = () => {
      loadData();
      state.page++;
    };
    const playLesson = (index) => {
      if(state.list.length < 1){
        showToast("该课程没有可播放内容");
        return;
      }
      if(index < 0 || index > state.list.length-1){
        showToast("指定内容不可播放");
        return;
      }
      let lesson = state.list[index];
      if(!lesson.trial) {
        showToast("购买该课程，解锁全部内容");
        return;
      }
      if (lesson.playing) {
        lesson.playing = false;
        oneKeyStatus.value = false;
        audio.pause();
      } else {
        if(oneKeyStatus.value && playIndex.value > -1) {
          state.list[playIndex.value].playing = false;
        }
        playIndex.value = index;
        lesson.playing = true;
        oneKeyStatus.value = true;
        audio.pause();
        audio.src = lesson.mediaUrl;
        audio.play();
      }

    };
    // 一键试听
    const toTrial = () => {
      active.value = 1;
    };
    const oneKeyPause = () => {
      if(playIndex.value > -1 && playIndex.value < state.list.length) {
        audio.pause();
        state.list[playIndex.value].playing = false;
        oneKeyStatus.value = false;
      }
    };
    const loadEvalData = async () => {
      let res = await getEvalList({courseId: course.value.id, pageNum: state.evalPage});
      state.evalList = state.evalList.concat(res.data.list);
      if(state.evalList.length < 1) {
        state.evalFinishedText = "暂无数据";
        state.evalFinished = true; //上拉结束
      }else if(state.evalList.length >= 20) {   //修改位res.data.list.length < 1 适配正式环境
        state.evalFinishedText = "没有更多了";
        state.evalFinished = true; //上拉结束
      } else {
        state.evalLoading = false;
      }
    }
    // 加载数据
    const onLoadEvaluation = () => {
      loadEvalData();
      state.evalPage++;
    };
    // 显示完整评价
    const showFullEval = (event) => {
      let el = event.target;
      el.classList.toggle("van-multi-ellipsis--l3");
    }

    // 分享
    const shareCourse = () => {
      showShare.value = true;
    }

    const addCar = () => {
      if (loginFlag.value) {
        //已登录
        console.log(judge.value)
        if(-1==judge.value){
          showToast("该书籍暂无库存！");
        }else if(-2==judge.value){
          showToast("全站会员已拥有该课程！");
        }else{
          addShopCar({packId: good.value.id, userId: token.userId}).then(res2 => {
            if (res2.data.status == 1) {
              showToast("加入购物车成功")
              carNum.value = parseInt(carNum.value) + 1;
            }
          })
        }
      } else {
        router.push("/login");
      }


    }

    // 显示购物车
    const toCart = () => {
      if (loginFlag.value) {
        if(carNum.value>0){
          router.push("/cart");
        }else{
          showToast("购物车空空如也");
        }

      } else {
        router.push("/login");
      }

    }
    onMounted(() => {
      const id = router.currentRoute.value.query.id;
      if (token) {
        loginFlag.value = ref(true);
        myCarNum(token.userId).then(res => {
          carNum.value = res.data.data;
        })

      }
      onLoad(id);
    })

    return {
      ...toRefs(state),
      router,
      active,
      course,
      audio,
      options,
      oneKeyStatus,
      onLoad,
      onLoadLessons,
      optionIndex,
      optionPopup,
      showOptions,
      selectOption,
      showKefu,
      showKefuPopup,
      buyNow,
      playLesson,
      toTrial,
      oneKeyPause,
      fiveStarts,
      showShare,
      shareCourse,
      getChecked,
      good,
      checked,
      carNum,
      addCar,
      onLoadEvaluation,
      showFullEval,
      toCart,
    };
  }
}
</script>

<style lang="scss">
.course-detail {
  font-size: 24px;
  overflow-y: scroll;
  .cover {
    position: relative;
    .intro {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      color: #fff;
      padding: 10px 24px;
      .name {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
      }
      .desc {
        font-size: 24px;
      }
      .count {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .van-tabs {
    .feature {
      // padding: 16px 16px 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .sell {
        line-height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding:0 16px;
        padding-top: 8px;
        .price {
          color: #F63515;
          font-size: 40px;
          font-weight: 500;
          .to{
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        .amount {
          color: #707070;
        }
      }
      .coupon {
        line-height: 56px;
        background-color: #fff;
        padding: 0 16px;
        font-size: 24px;
        color: rgb(242, 85, 0);
        position: relative;
        &::after {
          position: absolute;
          box-sizing: border-box;
          content: " ";
          pointer-events: none;
          right: 16px;
          bottom: 0;
          left: 16px;
          border-bottom: 1px solid var(--van-cell-border-color);
          transform: scaleY(.5);
        }
      }
      .delivery {
        display: flex;
        justify-content: flex-start;
        padding: 16px 16px;
        background: #fff;
        line-height: 36px;
        position: relative;
        .label {
          width: 80px;
          // color: #707070;
        }
        .content {
          .title {

          }
          .subtitle {
            color: #707070;
          }
        }
        &::after {
          position: absolute;
          box-sizing: border-box;
          content: " ";
          pointer-events: none;
          right: 16px;
          bottom: 0;
          left: 16px;
          border-bottom: 1px solid var(--van-cell-border-color);
          transform: scaleY(.5);
        }
      }
      .options {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 16px  24px ;
        background: #fff;
        line-height: 36px;
        .label {
          width: 80px;
          // color: #707070;
        }
        .title {
          display: flex;
          flex-wrap: wrap;
          color: #707070;
          .warning {
            color: var(--van-button-warning-background-color);
          }
          .selected {
            margin-left: 16px;
            padding: 8px 16px;
            color: #fff;
            background-color: #FF9966;
            border-radius: 8px;
          }
        }
        .title {
          flex: 1;
        }
      }
      .preface {
        margin-top: 8px;
        padding: 18px 16px;
        background-color: #fff;
        >.header {
          font-size: 28px;
          font-weight: 600;
          line-height: 56px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .content {
          display: flex;
          flex-direction: column;
          padding-top: 12px;
          // margin-bottom: 80px;
        }
      }
    }
    .catalog {
      .subtitle {
        font-size: 24px;
        line-height: 32px;
      }
      .count {
        margin-top: 8px;
        .item {
          &:not(:first-child) {
            margin-left: 16px;
          }
        }
      }
    }
    .evaluation {
      .van-cell {
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .user-info {
            flex: 1;
            display: flex;
            align-content: center;
            >:last-child {
              padding-left: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }
            .nickname {
              font-weight: 500;
            }
            .create-date {
              color: var(--van-cell-value-color);
            }
          }
        }
      }
    }
    padding-bottom: 100px;
  }
  .optionPopup {
    padding: var(--van-popup-close-icon-margin) var(--van-popup-close-icon-margin);
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      .info-right {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .price {
          color: #F63515;
          font-size: 40px;
          font-weight: 500;
        }
        .label {
          width: 80px;
          color: #707070;
        }
      }
    }
    .content {
      margin-top: 24px;
      .all {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        .item {
          padding: 8px 16px;
          border-radius: 8px;
          white-space: nowrap;
          margin-bottom: 16px;
          margin-right: 16px;
          border: 1px solid #cacaca;
        }
        >.active {
          border: 0;
          color: #fff;
          background-color: #FF9966;
        }
      }
    }
  }
  .van-image {
    vertical-align: top;
  }
  .kefuPopup {
    width: 370px;
    height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .kefu-tips {
      color: #F76968;
      font-size: 24px;
      padding-top: 8px;
      padding-left: 6px;
      padding-right: 6px;
      line-height: 34px;
      text-align: center;
    }
  }
}

.sell {
  line-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 16px;
  padding-top: 8px;
  .price {
    color: #F63515;
    font-size: 30px;
    font-weight: 500;
    .to {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .amount {
    color: #707070;
  }
}
.standard {
  margin: 10px 8px;
}
.describ{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
  .title{
    font-weight: bold;
  }
  .content{
    display: block;
    width: 500px;
    margin-left: 130px;
    margin-top: -30px;
  }
}
/*.goods-name {*/
  /*padding: 16px 16px;*/
  /*background: #fff;*/
  /*position: relative;*/
  /*.name {*/
    /*font-size: 28px;*/
    /*font-weight: 500;*/
    /*line-height: 56px;*/
  /*}*/
  /*.subtitle {*/
    /*line-height: 32px;*/
  /*}*/
  /*&::after {*/
    /*position: absolute;*/
    /*box-sizing: border-box;*/
    /*content: " ";*/
    /*pointer-events: none;*/
    /*right: 16px;*/
    /*bottom: 0;*/
    /*left: 16px;*/
    /*border-bottom: 1px solid var(--van-cell-border-color);*/
    /*transform: scaleY(.5);*/
  /*}*/
/*}*/
.delivery {
  display: flex;
  justify-content: flex-start;
  padding: 16px 16px;
  background: #fff;
  line-height: 36px;
  position: relative;
  .label {
    width: 80px;
    // color: #707070;
  }
  .content {
    .title {

    }
    .subtitle {
      color: #707070;
    }
  }
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid var(--van-cell-border-color);
    transform: scaleY(.5);
  }
}
</style>
